export const ApiUrl = 'http://erpstage.ayekart.com:8000';
//export const ApiUrl= ''128.199.23.167:8000
// export const ApiUrl = 'https://api.ayekart.com/biz';

export const ApiUrl1 = 'https://stagingerp.ayekart.com/';
// export const ApiUrl1 = 'http://139.59.91.183';

export const ApiUrl2 = 'https://stagingworkflow.ayekart.com';
// export const ApiUrl2 = 'https://139.59.79.10';


export const local_access = '';

// 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


// powesheell >
// ssh root@139.59.94.40
// password : dc9INzG08^9X
//  cd miniErp > git pull origin master 
// npm run build
// pm2 stop all
// then pm2 start all