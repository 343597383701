import { useNavigate } from "react-router-dom";

import addNewproduct from "./../assets/images/addNewProduct.png";
import cup from "./../assets/images/cup.png";
import merchant from "./../assets/images/merchant.png";
import logistic from "./../assets/images/logistic.png";
import reward from "./../assets/images/rewards.png";
import Header from "./Header";
import "./../assets/css/style.css";
import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { ApiUrl, local_access } from "../constant";
import Loader from "./loader";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


function Home() {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = React.useState({});
  const [counts, setCounts] = React.useState({});
  const [orderRe, setOrderRe] = React.useState(0);
  const [orderPl, setOrderPl] = React.useState(0);
  const [year, setYear] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState(2022)
  const [barData, setBarData] = React.useState({
    labels,
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: '#23c1e2',
      },
      {
        label: '',
        data: [],
        backgroundColor: '#286d94',
      },
    ],
  });
  const [loader, setLoader] = React.useState(false);
  function redirectTo(page) {
    navigate("/"+page);
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userInfo'))
    console.log(user)
    setUserInfo(user)    
},[])

useEffect(() => {
  getGraph(selectedYear)
  getCount()
  let y = year;
  for(let i = 0; 40 > i; i++){  
    y.push(2020 + i)  
    setYear(y)
  }
},[userInfo])

useEffect(() => {
  getGraph(selectedYear)
},[selectedYear])

  const getGraph =  (year) => {
    if(userInfo.ID){
    setLoader(true)

    const requestOptions = {
        crossDomain:true,
        method: 'GET',            
        headers: { 'Content-Type': 'application/json', 
        'Access-Control-Allow-Origin':'*', }
    };
    let url = `/v1/merchant/${userInfo.ID}/orders-stats?year=${year}`;
    fetch(`${local_access}${url}`, requestOptions)
    .then(response => response.json())
    .then(data => {
      setLoader(false)
      if(data.code === 200){
        let placedOrder = [];
        if(data.data.consumer_to_merchant_orders){
          for(let i = 0; data.data.consumer_to_merchant_orders.length > i; i++ ){
            if(data.data.merchat_to_merchant_orders[i]){
              placedOrder.push(data.data.merchat_to_merchant_orders[i].value + data.data.consumer_to_merchant_orders[i].value)
            }else{
              placedOrder.push(data.data.consumer_to_merchant_orders[i].value)
            }
            if((i+1) == data.data.consumer_to_merchant_orders.length){
              console.log(i)
              setBarData({
                labels,
                datasets: [
                  {
                    label: 'Orders received',
                    data: placedOrder,
                    backgroundColor: '#23c1e2',
                  },
                  {
                    label: 'Orders placed',
                    data: data.data.merchant_placed_orders ? data.data.merchant_placed_orders.map(item => item.value) : [],
                    backgroundColor: '#286d94',
                  },
                ],
              })
            }
          }
        }else{
          setBarData({
            labels,
            datasets: [
              {
                label: 'Orders received',
                data: placedOrder,
                backgroundColor: '#23c1e2',
              },
              {
                label: 'Orders placed',
                data: data.data.merchant_placed_orders ? data.data.merchant_placed_orders.map(item => item.value) : [],
                backgroundColor: '#286d94',
              },
            ],
          })
        }
        
        console.log(placedOrder)

        setOrderPl(placedOrder.reduce((partialSum, a) => partialSum + a, 0))
        setOrderRe(data.data.merchant_placed_orders ? data.data.merchant_placed_orders.reduce((partialSum, a) => partialSum + a.value, 0) : 0)
      }
      
    });
  }
}

const getCount = () => {
  if(userInfo.ID){
  setLoader(true)

    const requestOptions = {
        crossDomain:true,
        method: 'GET',            
        headers: { 'Content-Type': 'application/json', 
        'Access-Control-Allow-Origin':'*', }
    };
    let url = `/v1/merchant/${userInfo.ID}/erp-stats`;
    fetch(`${local_access}${url}`, requestOptions)
    .then(response => response.json())
    .then(data => {
      setLoader(false)
      if(data.code === 200){
        setCounts(data.data)
      }
      
    });
  }
}

  return (
    <div>
    
    <Loader loading={loader} />
      <Header />
      <div className="container-fluid">
        <div className="row justify-content-md-center">
          <div className="col col-12 col-md-12 col-lg-2 text-center">
            <img alt=""   onClick={() => navigate('/addProduct')} style={{cursor:'pointer'}} src={addNewproduct} /><br></br><br></br>
            <img alt=""  data-toggle="modal" data-target="#exampleModal1" src={cup} style={{cursor:'pointer'}} />
          </div>
          <div className="col col-12 col-md-12 col-lg-8">
            <div className="dashboard-container row justify-content-md-center">
              <div className="col col-12 col-md-6 col-lg-4">
                <div
                  className="Rounded-Rectangle-2-copy"
                  style={{ backgroundColor: "#17a2b8" }}
                >
                  <div className="more-info-above">
                    <img
                      alt=""
                      src={merchant}
                      style={{ width: "20px", marginBottom: "10px" }}
                    />
                    <p>Merchant Directory</p>
                    <p style={{ fontSize: "12px" }}>
                      All your merchants / Suppliers in single click
                    </p>
                    <p style={{ fontSize: "12px" }}>
                      Number of onboarded merchants
                    </p>
                    <h5>{counts?.merchant_directory_count}</h5>
                  </div>
                  <div className="more-info">
                    <a
                    style={{ cursor: 'pointer' }}
                      href={() => false}
                      className="text-white"
                      onClick={() => redirectTo("directory")}
                    >
                      More Info{" "}
                      <i className="fas fa-chevron-circle-right">
                        fa-chevron-circle-right
                      </i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-md-6 col-lg-4">
                <div
                  className="Rounded-Rectangle-2-copy"
                  style={{ backgroundColor: "#dc3545" }}
                >
                  <div className="more-info-above">
                    <img
                      alt=""
                      src="assets/images/offer.png"
                      style={{ width: "20px", marginBottom: "10px" }}
                    />
                    <p>Recieved Orders</p>
                    <p style={{ fontSize: "12px" }}>
                      Manage all orders placed by your customers
                    </p>
                    <p style={{ fontSize: "12px" }}>
                      Pending orders for further action{" "}
                    </p>
                    <h5>{counts?.received_order_count}</h5>
                  </div>
                  <div
                    className="more-info"
                    style={{ backgroundColor: "#c6303e" }}
                  >
                    <a style={{ cursor: 'pointer' }} className="text-white"  href={() => false} onClick={() => redirectTo("recievedOrder")}>
                      More Info{" "}
                      <i className="fas fa-chevron-circle-right">
                        fa-chevron-circle-right
                      </i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-md-6 col-lg-4">
                <div
                  className="Rounded-Rectangle-2-copy"
                  style={{ backgroundColor: "#ffc107" }}
                >
                  <div className="more-info-above">
                    <img
                      alt=""
                      src="assets/images/demand.png"
                      style={{ width: "20px", marginBottom: "10px" }}
                    />
                    <p style={{color:'#000'}}>Demand</p>
                    <p style={{ fontSize: "12px", color:'#000' }}>
                      Demand goods / raw material for your business
                    </p>
                    <p style={{ fontSize: "12px", color:'#000' }}>Number of open demands</p>
                    <h5 style={{color:'#000'}}>{counts?.demand_count}</h5>
                  </div>
                  <div
                    className="more-info"
                    style={{ backgroundColor: "#e5ad06" }}
                  >
                    <a href={() => false} onClick={() => redirectTo("demand")} style={{ cursor: 'pointer' }} className="text-black">
                      More Info{" "}
                      <i className="fas fa-chevron-circle-right">
                        fa-chevron-circle-right
                      </i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-md-6 col-lg-4">
                <div
                  className="Rounded-Rectangle-2-copy"
                  style={{ backgroundColor: "#28a745" }}
                >
                  <div className="more-info-above">
                    <img
                      alt=""
                      src="assets/images/finance.png"
                      style={{ width: "20px", marginBottom: "10px" }}
                    />
                    <p>Finance</p>
                    <p style={{ fontSize: "12px" }}>
                      Create invoice, POs, BOS and manage amount to be settled /
                      collected
                    </p>
                    <p style={{ fontSize: "12px" }}>
                      Pending orders for further action{" "}
                    </p>
                    <h5>{counts?.received_order_count}</h5>
                  </div>
                  <div
                    className="more-info"
                    style={{ backgroundColor: "#24963e" }}
                  >
                    <a href={() => false} onClick={() => redirectTo("finance")} style={{ cursor: 'pointer' }} className="text-white">
                      More Info{" "}
                      <i className="fas fa-chevron-circle-right">
                        fa-chevron-circle-right
                      </i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-md-6 col-lg-4">
                <div
                  className="Rounded-Rectangle-2-copy"
                  style={{ backgroundColor: "#5f17b8" }}
                >
                  <div className="more-info-above">
                    <img
                      alt=""
                      src="assets/images/inventory.png"
                      style={{ width: "20px", marginBottom: "10px" }}
                    />
                    <p>Inventory</p>
                    <p style={{ fontSize: "12px" }}>
                      Manage inventory for easy tracking
                    </p>
                    {/* <p style={{ fontSize: "12px" }}>SKUs expired</p>
                    <h5>8</h5> */}                    
                    <h5>{counts?.product_count}</h5>
                  </div>
                  <div
                    className="more-info"
                    style={{ backgroundColor: "#7727db" }}
                  >
                    <a href={() => false} className="text-white"  style={{ cursor: 'pointer' }} onClick={() => redirectTo("inventory")}>
                      More Info{" "}
                      <i className="fas fa-chevron-circle-right">
                        fa-chevron-circle-right
                      </i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-md-6 col-lg-4">
                <div
                  className="Rounded-Rectangle-2-copy"
                  style={{ backgroundColor: "#17b8a7" }}
                >
                  <div className="more-info-above">
                    <img
                      alt=""
                      src="assets/images/order.png"
                      style={{ width: "20px", marginBottom: "10px" }}
                    />
                    <p>My Orders</p>
                    <p style={{ fontSize: "12px" }}>Manage placed orders</p>
                    <p style={{ fontSize: "12px" }}>
                      Orders pending for delivery
                    </p>
                    <h5>{counts?.my_order_count}</h5>
                  </div>
                  <div
                    className="more-info"
                    style={{ backgroundColor: "#11897c" }}
                  >
                    <a style={{ cursor: 'pointer' }} onClick={() => redirectTo("order")} href={() => false} className="text-white">
                      More Info{" "}
                      <i className="fas fa-chevron-circle-right">
                        fa-chevron-circle-right
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-lg-2 text-center">
            <img alt="" style={{width:'100%', cursor:'pointer', 
    maxWidth: '140px'}}  data-toggle="modal" data-target="#exampleModal1" src={logistic} /><br></br><br></br>
            <img alt="" style={{width:'100%', cursor:'pointer', 
    maxWidth: '140px'}}  data-toggle="modal" data-target="#exampleModal1"  src={reward} />
          </div>
        </div>
        <div className="container">
        <div
          className="row text-center"
          style={{ marginTop: "30px", marginBottom: "30px" }}
        >
          <div className="col col-lg-9">
            <h4 className="chart-text">
              Total Orders Recieved vs total orders placed
            </h4>
          </div>
          <div className="col col-lg-3">
            <h4 className="chart-text">
              <select className="form-control" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
              {
                year.map((item,index) => <option key={index} value={item}>{item}</option>)
              }
                
              </select>
            </h4>
          </div>          
        <div className="col-sm-12 text-center">
          <Bar data={barData} />
        </div>
        <div
          className="row col-lg-12 text-center"
          style={{ marginTop: "30px", marginBottom: "60px" }}
        >
          <div className="col col-lg-6">
            <h6 className="chart-text">
              Total Value of order placed:{" "}
              <span style={{ color: "#286d94" }}>Rs. {parseFloat(orderPl).toFixed(2)} K</span>
            </h6>
          </div>
          <div className="col col-lg-6">
            <h6 className="chart-text">
              Total value of order recieved:{" "}
              <span style={{ color: "#286d94" }}>Rs. {parseFloat(orderRe).toFixed(2)} K</span>
            </h6>
          </div>
        </div>
        </div>
        </div>
      </div>
        <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
            <div class="modal-body" style={{textAlign: 'left'}}>
                <h4>Coming soon</h4>
            </div>
            </div>
        </div>
        </div>
        <Toaster />
    </div>
  );
}

export default Home;
