import searchIcon from "./../assets/images/search-icon.png";
import user2 from "./../assets/images/user2.jpg";
import addNew from "./../assets/images/add-new.png";
import { ApiUrl, ApiUrl1, ApiUrl2, local_access } from "../constant";
import Header from "./Header";
import './../assets/css/style.css'
import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Loader from "./loader";
import { confirm } from "react-confirm-box";
import { useNavigate } from "react-router-dom";

function Directory() {
    const [merchant, setMerchants] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [addData, setAddData] = React.useState({});
    const [categories,setCategories] = React.useState([]);
    const [userInfo, setUserInfo] = React.useState({})
    const [loader, setLoader] = React.useState(false);
    const [merchantID,setMerchantId] = React.useState(null)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userInfo'))
        console.log(user)
        setUserInfo(user)    
        getCategories();
    },[])

    useEffect(() => {    
        getOrders(userInfo);
    },[userInfo])

    const getOrders = (user) => {
        setLoader(true)
        if(userInfo.ID){
            try{
        const requestOptions = {
            crossDomain:true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/ayekart/api/b2b/merchant_get?user_id=${user.ID}`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
            if(data.message === 'success'){
                console.log(data?.bank_details)
                setMerchants(data?.bank_details)
            }else{
                // toast.error('no data found')
            }
        });
    }catch{
        setLoader(false)
    }
    }
    }

    const getCategories = () => {
        const requestOptions = {
            crossDomain:true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        // http://128.199.23.167:8000/
        // https://api.ayekart.com/
        let url = `/v1/merchant/category`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.code === 200){
                let items = [];
                for(let item of data?.data){
                    items.push({...item, name:item.category_name})
                }
                setCategories(items)
            }
        });
    }

    const newMerchange = () => {
        setLoader(true)
        const dataToAdd = addData;
        dataToAdd['user_id'] = userInfo.ID;
        try{
        const requestOptions = {
            crossDomain:true,
            method: 'POST',            
            body: JSON.stringify(dataToAdd),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/v1/merchant/diretory/${userInfo.ID}`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
            if(data.code === 200){
                toast.success('Merchant added succesfully');
                console.log(data.code)
                setAddData({})
                getOrders(userInfo);
            }else{
                // toast.error('something went wrong')
            }
        });
    }catch{
        setLoader(false)
    }
    }
    // https://ayekartproxycorsserver.herokuapp.com/
    const editMerchant = () => {
        setLoader(true)
        const dataToAdd = addData;
        dataToAdd['user_id'] = userInfo.ID;
        try{
        const requestOptions = {
            crossDomain:true,
            method: 'PATCH',            
            body: JSON.stringify(dataToAdd),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/ayekart/api/b2b/merchant_update/?id=${merchantID}`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false);
            if(data.data === 1){
                toast.success('Merchant Edited successfully');
                setAddData({})
                setMerchantId(null);
                getOrders(userInfo);
            }else{
                toast.error('something went wrong')
            }
        });
    }catch{
        setLoader(false)
    }
    }

    const removeMerchange = async (id) => {
        const result = await confirm('Are you sure you want to delete this merchant')
        if (result) {
            // Save it!
            setLoader(true)
            try{
            const requestOptions = {
                crossDomain:true,
                method: 'DELETE',            
                headers: { 'Content-Type': 'application/json', 
                'Access-Control-Allow-Origin':'*', }
            };
            let url = `/ayekart/api/b2b/merchant_delete/?id=${id}`;
            fetch(`${local_access}${url}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setLoader(false)
                if(data.message === 'done'){
                    toast.success('Merchant Deleted from directory');
                    console.log(data.message)
                    setAddData({})
                    getOrders(userInfo);
                }else{
                    toast.error('something went wrong')
                }
            });
        }catch{
            setLoader(false)
        }
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
        
    }

    const onChangeInput = (e) => {
        setAddData({...addData,[e.target.name]:e.target.value})
    }
  
    const modalData = (data) => {
        console.log(data)
        setAddData({
            "user_id":userInfo.ID,
            "user_category_id":data.user_category_id,
            "user_category_name":data.user_category_name,
            "first_name":data.first_name,
            "last_name": data.last_name,
            "business_name": data.business_name,
        }
        )
        setMerchantId(data.id)
    }

    const createNew = () => {
        setAddData({"user_id":'',
        "user_category_id":'',
        "user_category_name":'',
        "first_name":'',
        "last_name": '',
        "business_name": '',})
    }

    const userCategoryselect = (e) => {
        for(let item of categories){
            if(Number(item.ID) === Number(e.target.value)){
                console.log(e.target.value)
                setAddData({...addData,user_category_id:item.ID,user_category_name:item.category_name})
            }
        }
    }


    const navigate = useNavigate();
  return (
    <div>
    
    <Loader loading={loader} />
      <Header />
      <div className="container-fluid mt-3">
      <div className="custom-breadcrum">
        <span className="disable-color"  style={{cursor:'pointer'}} onClick={() => navigate('/home')}>Home</span> 
        <span className="disable-color"> Merchant Directory</span>
      </div>
      <p
        style={{ 
          fontSize: '35px',
          color: '#565e7d',
          fontWeight: '600',
          letterSpacing: '1px'
        }}
      >
        Directory
      </p>
      <p className="disable-color">Add and find merchant</p>
      <hr />
    </div>
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col col-12 col-md-12 col-lg-9 text-left">
            <div className="row">
                {/* <div className="col col-12 col-md-12 col-lg-4 text-left">
                    <p className="search-tab">Search </p>
                </div> */}
                {/* <div className="col col-12 col-md-12 col-lg-4 text-left">
                    <p className="directory-tab">Key Contacts <span className="text-danger">(45)</span></p>
                </div>
                <div className="col col-12 col-md-12 col-lg-4 text-center">
                    <p className="directory-tab">Who do i talk to <span className="text-danger">(12)</span></p>
                </div> */}
            </div>
        </div>
        <div className="col col-12 col-md-12 col-lg-3 text-right">
            <button className="directory-add-new-button" data-toggle="modal" data-target="#exampleModal" onClick={() => createNew()}>Add New <img alt="" width="18" src={addNew} /></button>
        </div>
      </div>

      <div className="row mt-5">
        {/* <div className="col col-12 col-md-12 col-lg-2">
            <div className="col-xs-12 col-sm-12 filter-option-left-ui">
                <div id="accordion" className="panel panel-primary behclick-panel">
                    <div className="panel-heading" style={{ borderTop: 'none' }}>
                        <p className="panel-title" style={{ marginBottom: '10px' }}>Filter</p>
                    </div>
                    <div className="panel-body" >
                        <div className="panel-heading " >
                            <p className="panel-title">
                                <a className="text-dark" data-toggle="collapse" href="#collapse0">
                                    <i className="indicator fa fa-caret-down" aria-hidden="true"></i> Division
                                </a>
                            </p>
                        </div>
                        <div id="collapse0" className="panel-collapse collapse in" >
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <div className="checkbox">
                                        <label>
                                            <input type="checkbox" value=""></input>
                                            Manufacturer
                                        </label>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="checkbox" >
                                        <label>
                                            <input type="checkbox" value=""></input>
                                            Distributor
                                        </label>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="checkbox"  >
                                        <label>
                                            <input type="checkbox" value=""></input>
                                            Retailer
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="panel-heading " >
                            <p className="panel-title">
                                <a className="text-dark" data-toggle="collapse" href="#collapse1">
                                    <i className="indicator fa fa-caret-down" aria-hidden="true"></i> Location
                                </a>
                            </p>
                        </div>
                        <div id="collapse1" className="panel-collapse collapse in" >
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <div className="checkbox">
                                        <label>
                                            <input type="checkbox" value=""></input>
                                            Manufacturer
                                        </label>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="checkbox" >
                                        <label>
                                            <input type="checkbox" value=""></input>
                                            Distributor
                                        </label>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="checkbox"  >
                                        <label>
                                            <input type="checkbox" value=""></input>
                                            Retailer
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="panel-heading " >
                            <p className="panel-title">
                                <a className="text-dark" data-toggle="collapse" href="#collapse2">
                                    <i className="indicator fa fa-caret-down" aria-hidden="true"></i> Branch
                                </a>
                            </p>
                        </div>
                        <div id="collapse2" className="panel-collapse collapse in" >
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <div className="checkbox">
                                        <label>
                                            <input type="checkbox" value=""></input>
                                            Manufacturer
                                        </label>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="checkbox" >
                                        <label>
                                            <input type="checkbox" value=""></input>
                                            Distributor
                                        </label>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="checkbox"  >
                                        <label>
                                            <input type="checkbox" value=""></input>
                                            Retailer
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="panel-heading " >
                            <p className="panel-title">
                                <a className="text-dark" data-toggle="collapse" href="#collapse3">
                                    <i className="indicator fa fa-caret-down" aria-hidden="true"></i> Location
                                </a>
                            </p>
                        </div>
                        <div id="collapse3" className="panel-collapse collapse in" >
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <div className="checkbox">
                                        <label>
                                            <input type="checkbox" value=""></input>
                                            Manufacturer
                                        </label>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="checkbox" >
                                        <label>
                                            <input type="checkbox" value=""></input>
                                            Distributor
                                        </label>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="checkbox"  >
                                        <label>
                                            <input type="checkbox" value=""></input>
                                            Retailer
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="col col-12 col-md-12 col-lg-12">
                {/* <div class="input-group mb-3">
                    <input className="form-control" type="search" placeholder="search" onChange={(e) => setSearch(e.target.value)} value={search} />
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" onClick={() => getOrders(userInfo)} disabled={search === ''} type="button">Search</button>
                    </div>
            </div> */}
            {
                merchant && merchant.map((item,index) => {
                    return (
                    
                        <div className="directory-user-ui"> 
                                <div className="row" key={index}>
                                    <div className="col col-12 col-md-10 col-lg-11">
                                        {
                                            item?.image_URL ?
                                            <img  className="user-avatar" src={item.image_URL} alt={item?.first_name}/>
                                            :
                                            <span className="img-txt">
                                            {item.first_name.charAt(0)}{item.last_name.charAt(0)}
                                            </span>
                                        
                                        }
                                        <div className="flex user-detail">
                                            <h5>{item.first_name} {item.last_name}</h5>
                                            <small>{item.user_category_name}</small>
                                            <p><a href={() => false} data-abc="true">{item.business_name}</a></p>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-1 col-lg-1">
                                        <div className="dropdown dropdown-action action-option">
                                            <a href={() => false} className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                            <div className="dropdown-menu user-option">
                                                <a className="dropdown-item" href={() => false}  data-toggle="modal" data-target="#exampleModal1" onClick={() => modalData(item)}>EDIT</a>
                                                {/* <a className="dropdown-item" href={() => false}>ADD TO KEY CONTACTS</a>
                                                <a className="dropdown-item" href={() => false}>ADD TO WHO DO I TALK TO</a> */}
                                                <a className="dropdown-item" href={() => false} onClick={() => removeMerchange(item.id)}>REMOVE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    )
                })
            }

        </div>
      </div>

    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style={{textAlign: 'left'}}>
                <div className="form-group">
                    <input placeholder="First Name" type={'text'} className="form-control" name="first_name" onChange={(e) => onChangeInput(e)} value={addData['first_name']} />
                </div>
                <div className="form-group">
                    <input placeholder="Last Name" type={'text'} className="form-control" name="last_name" onChange={(e) => onChangeInput(e)} value={addData['last_name']} />
                </div>
                <div className="form-group">
                    <input placeholder="Business Name" type={'text'} className="form-control" name="business_name" onChange={(e) => onChangeInput(e)} value={addData['business_name']} />
                </div>
                
                <div className="form-group">
                    <select  className="form-control" name="business_category_id" value={addData.user_category_id} onChange={(e) => userCategoryselect(e)} >
                       <option value="">Select User category</option>
                       {
                        categories.map(item => <option value={item.ID}>{item.category_name}</option>)
                       } 
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary"   data-dismiss="modal" onClick={() => newMerchange()}>Add</button>
            </div>
            </div>
        </div>
        </div>
    
        <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel1"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style={{textAlign: 'left'}}>
            <div className="form-group">
                    <input placeholder="First name" type={'text'} className="form-control" name="first_name" onChange={(e) => onChangeInput(e)} value={addData['first_name']} />
                </div>
                <div className="form-group">
                    <input placeholder="Last name" type={'text'} className="form-control" name="last_name" onChange={(e) => onChangeInput(e)} value={addData['last_name']} />
                </div>
                <div className="form-group">
                    <input placeholder="Business name" type={'text'} className="form-control" name="business_name" onChange={(e) => onChangeInput(e)} value={addData['business_name']} />
                </div>
                
                <div className="form-group">
                    <select className="form-control" name="user_category_id" value={addData['user_category_id']} onChange={(e) => userCategoryselect(e)} >
                       <option value="">Select User category</option>
                       {
                        categories.map(item => <option value={item.ID}>{item.category_name}</option>)
                       } 
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary"  data-dismiss="modal" onClick={() => editMerchant()}>Edit</button>
            </div>
            </div>
        </div>
        </div>
        <Toaster />
    </div>
  );
}

export default Directory;
