import React, { useCallback, useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ApiUrl, ApiUrl1, local_access } from "../constant";
import {useDropzone} from 'react-dropzone';
import Header from "./Header";
import Loader from "./loader";
import aws from "aws-sdk";
import DropBox from "./DropBox";
const fileTypes = ["JPG", "PNG", "GIF"];
function AddProduct(){
    const navigate = useNavigate();
    function redirectTo(page) {
        navigate("/"+page);
    }
    const [loader, setLoader] = useState(false);
    const [urlFile,setUrlFile] = useState([])
    const [addData, setAddData] = React.useState({'product_category_id' : '',
    'product_sub_category_id' : '',
    'product_name' : '',
    'origin_from' : '',
    'min_order_qty' : '',
    'measurement_unit' : '',
     'gst':''
     });
    const [categories, setCategories] = React.useState([]);
    const [Commodities, setCommodites] = React.useState([]);
    const [units, setUnits] = React.useState([])
    const [formErrors, setFormErrors] = React.useState({
        'product_category_id' : '',
        'product_sub_category_id' : '',
        'product_name' : '',
        'origin_from' : '',
        'min_order_qty' : '',
        'measurement_unit' : '',
        'gst':'',
    })

    const [formValid, setFormValid] = React.useState({
        'product_category_id_valid' : false,
        'product_sub_category_id_valid' : false,
        'product_name_valid' : false,
        'origin_from_valid' : false,
        'min_order_qty_valid' : false,
        'measurement_unit_valid' : false,
        
    })

    const [formValidated, setFormValidate] = React.useState(false)

    const [userInfo, setUserInfo] = React.useState({});
    const [pack, setPack] = React.useState([{
        "buyPrice":0,
        "discPerc":0,
        "packSize":0,
        "presence":false,
        "sellPrice":0,
        "splDisc":0,
        "stock":0,
        "unit":""
    }])

    const [images, setImages] = useState([]);
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.map((file, index) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            setImages((prevState) => [
            ...prevState,
            { id: index, src: e.target.result },
            ]);
        };
        reader.readAsDataURL(file);
        return file;
        });
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userInfo'))
        setUserInfo(user)    
        getCategory();
        getUnits();
    },[])


    const getSignUrl = (file) => {
        console.log(file)
        setLoader(true)
        const s3 = new aws.S3({
          endpoint: "https://ayekart-mobile.sfo2.digitaloceanspaces.com",
          accessKeyId: "JZXLSMYTTPFGOUKX46ZW",
          secretAccessKey: "iOU3OJckYIMvOiQSsjImYSP8KjJ1b1GnBh3TNKIQkTo",
        });
    
        var config = {
          Body: file,
          Bucket:
            "a4920e07cf09ce0e60dff28729322c22fbbf4bbb4d9663ca8428d0a8b73fe03a",
          // Key: "Test/sampleisssmage.jpg", 
          Key: file.name,
        };
    
        s3.putObject(config)
          .on("build", (request) => {
            request.httpRequest.headers.Host =
              "https://ayekart-mobile.sfo2.digitaloceanspaces.com";
            request.httpRequest.headers.Host =
              "http://ayekart-mobile.sfo2.digitaloceanspaces.com";
            request.httpRequest.headers["Content-Length"] = file.size;
            request.httpRequest.headers["Content-Type"] = "image/jpg";
            request.httpRequest.headers["x-amz-acl"] = "public-read";
            request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
          })
          .send((err, data) => {
            setLoader(false)
            if (err) {
              console.log("Failed to upload file", `${err}`);
            } else {
                setUrlFile([...urlFile,'https://ayekart-mobile.sfo2.digitaloceanspaces.com/a4920e07cf09ce0e60dff28729322c22fbbf4bbb4d9663ca8428d0a8b73fe03a/'+file.name])
            }
          });
      };

    

    const getCategory = () => {
        const requestOptions = {
            crossDomain:true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/v1/merchant/product/categories`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.code === 200){
                setCategories(data?.data)
            }
        });
    }

    const getUnits = () => {
        const requestOptions = {
            crossDomain:true,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/units`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
                setUnits(data ? data : [])
        });
    }

    const removeImage = (i) => {
        const sFileD = urlFile;
        sFileD.splice(i,1);
        setUrlFile([...sFileD])
    }

    const onChangeInput = (e) => {
        if(e.target.name === 'product_category_id'){
            for(let item of categories){
                if(parseInt(item.ID) === parseInt(e.target.value)){
                    
                    console.log(e.target.value, e.target.name)
                    setCommodites(item.ProductSubCategory)
                }
            }
        }
        setAddData({...addData,[e.target.name]:e.target.value})
        validateField(e.target.name, e.target.value)
    }

    const checkValidation = () => {
        for(let key in addData){
            validateField(key,addData[key])
        }
     }

    const validateField = (fieldName, value)=> {
        let fieldValidationErrors = formErrors;
        let formValidation = formValid;
        switch(fieldName) {
          case 'product_name':
            formValidation.product_name_valid = value !== '';
            fieldValidationErrors.product_name = formValid.product_name_valid ? '' : ' is invalid';
            break;
        case 'product_category_id':
            formValidation.product_category_id_valid = value !== '';
            fieldValidationErrors.product_category_id = formValid.product_category_id_valid ? '' : ' is invalid';
            break;
        case 'product_sub_category_id':
            formValidation.product_sub_category_id_valid = value !== '';
            fieldValidationErrors.product_sub_category_id = formValid.product_sub_category_id_valid ? '' : ' is invalid';
            break;
        case 'origin_from':
            formValidation.origin_from_valid = value !== '';
            fieldValidationErrors.origin_from = formValid.origin_from_valid ? '' : ' is invalid';
            break;
        case 'min_order_qty':
            formValidation.min_order_qty_valid = value !== '';
            fieldValidationErrors.min_order_qty = formValid.min_order_qty_valid ? '' : ' is invalid';
            break;
        case 'measurement_unit':
            formValidation.measurement_unit_valid = value !== '';
            fieldValidationErrors.measurement_unit = formValid.measurement_unit_valid ? '' : ' is invalid';
            break;
        case 'gst':
            formValidation.gst_valid = value !== '';
            fieldValidationErrors.gst = formValid.gst_valid ? '' : ' is invalid';
            break;

          default:
            break;
        }
        
        setFormErrors({...fieldValidationErrors})
        setFormValid({...formValidation})
        
      }

      useEffect(() => {
        validateForm()
      },[formErrors,formValid])
      
      const validateForm = () => {
        setFormValidate(formValid.product_category_id_valid && 
            formValid.product_sub_category_id_valid && 
            formValid.product_name_valid && 
            formValid.origin_from_valid && 
            formValid.min_order_qty_valid && 
            formValid.measurement_unit_valid 
           
            )
      }

      const errorClass = (error) => {
        return(error.length === 0 ? '' : 'has-error');
     }

    
      

    const onChangeCheckPresense = (e) => {
        addData['presence'] = !addData['presence'];
        setAddData({...addData})
    }
    const onChangeCheckGlobal = (e) => {
        addData['global_delivery'] = !addData['global_delivery'];
        setAddData({...addData})
    }

    const newProduct =  () => {
        if(formValidated){
        setLoader(true)
        const dataToAdd = addData;
        dataToAdd["product_images"] = urlFile;
        dataToAdd['pack_size'] = pack.length;
        dataToAdd['pack_sizes'] = JSON.stringify({multiPacks:pack});
        dataToAdd['user_id'] = userInfo.ID;
        dataToAdd['product_category_id'] = parseInt(addData['product_category_id'])
        dataToAdd['product_sub_category_id'] = parseInt(addData['product_sub_category_id'])
        dataToAdd['business_margin'] = parseFloat(addData['business_margin']);
        dataToAdd['min_order_qty'] = parseInt(addData['min_order_qty']);
        dataToAdd['gst']= parseInt(addData['gst']);

        const requestOptions = {
            crossDomain:true,
            method: 'POST',            
            body: JSON.stringify(dataToAdd),
            headers: { 'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin':'*', }
        };
        let url = `/v2/merchant/${userInfo.ID}/product`;
        fetch(`${local_access}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setLoader(false)
            if(data.code === 201){
                setAddData({'product_category_id' : '',
                'product_sub_category_id' : '',
                'product_name' : '',
                'origin_from' : '',
                'min_order_qty' : '',
                'measurement_unit' : '',
                'gst': ''})
                toast.success('products added succesfully')
                redirectTo('inventory')
            }else{
                toast.error('something went wrong')
            }
        });
    }else{
        checkValidation()
    }
    }

    const onPackChange = (e,i) => {
        const packs = pack;        
        packs[i][e.target.name] = e.target.value; 
        if(parseInt(packs[i].buyPrice) < parseInt(packs[i].sellPrice)){
            toast.error('Enter offer price less than or equal to MRP')
        }else{            
            setPack([...packs]);
            calculatePercent(i)
        }    
    }

    const calculatePercent = (i) => {
        const packs = pack;    
        let calCDisc = parseInt(packs[i].buyPrice) - parseInt(packs[i].sellPrice)
        const discPerc = (calCDisc/parseInt(packs[i].buyPrice)) * 100;
        packs[i]['discPerc'] = discPerc;
        setPack([...packs]);
    }


    const addPackSize = () => {
        setPack([...pack,{
            "buyPrice":0,
            "discPerc":0,
            "packSize":0,
            "presence":false,
            "sellPrice":0,
            "splDisc":0,
            "stock":0,
            "unit":""
        }])
    }

    
    const removePackSize = (index) => {
        const packs = pack;
        packs.splice(index,1);        
        setPack([...packs])
    }

    const convertURIToImageData = (url) => {

    }

    return(
        <div>
        <Loader loading={loader} />
          <Header />
          <div className="container-fluid mt-3">
            <div className="custom-breadcrum">
                <span className="disable-color" style={{cursor:'pointer'}} onClick={() => navigate('/home')}>Home </span> 
                <span className="disable-color"> Add product</span>
            </div>
            <hr></hr>
        </div>
        <div className="container mt-3">
            <div className="row">
                <div className="col-md-12  file-container">
                    <DropBox handleFileChange={getSignUrl} removeImage={removeImage} images={[]} convertURIToImageData={convertURIToImageData} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-7">
                    <div className={`input-container-pro ${errorClass(formErrors.product_category_id)}`}>
                        <label>Category*</label>
                        <select className="input-box form-control" name="product_category_id" onChange={(e) => onChangeInput(e)} value={addData['product_category_id']}>
                            <option value={''}>Select Category</option>
                            {
                                categories.map(item => <option value={item.ID}>{item.name}</option>)
                            }
                        </select>
                        {
                            formErrors.product_category_id !== '' &&
                            <span className="error">*Product category is required</span>
                        }
                    </div>
                    {
                        Commodities && Commodities.length > 0 && 
                        
                        <div className={`input-container-pro ${errorClass(formErrors.product_sub_category_id)}`}>
                            <label>Subcategory*</label>
                            <select className="input-box form-control" name="product_sub_category_id" onChange={(e) => onChangeInput(e)} value={addData['product_sub_category_id']}>
                                <option value={''}>Select Subcategory</option>
                                {
                                    Commodities.map(item => <option value={item.ID}>{item.name}</option>)
                                }
                            </select>
                            {
                                formErrors.product_sub_category_id !== '' &&
                                <span className="error">*Product Sub category is required</span>
                            }
                    </div> 
                    }                   
                    <div className={`input-container-pro ${errorClass(formErrors.product_name)}`}>
                        <label>Product Name*</label>
                        <input type={'text'} name="product_name" onChange={(e) => onChangeInput(e)} value={addData['product_name']}  className="input-box form-control" placeholder="Enter Product name" />
                        {
                            formErrors.product_name !== '' &&
                            <span className="error">*Product name is required</span>
                        }
                    </div>                   
                    <div className="input-container-pro">
                        <label>Product Description</label>
                        <textarea className="input-box form-control" name="product_desc" onChange={(e) => onChangeInput(e)} value={addData['product_desc']} placeholder="Enter Product Description"></textarea>
                    </div>
                                                        
                    <div className="input-container-pro">
                        <label>Ayekart business margin</label>
                        <input type={'text'}  name="business_margin" onChange={(e) => onChangeInput(e)} value={addData['business_margin']} className="input-box form-control" placeholder="Ayekart business margin" />
                    </div>                                        
                    <div className={`input-container-pro ${errorClass(formErrors.origin_from)}`}>
                        <label>Origin From*</label>
                        <input type={'text'}  name="origin_from" onChange={(e) => onChangeInput(e)} value={addData['origin_from']}  className="input-box form-control" placeholder="Origin From" />
                        
                        {
                            formErrors.origin_from !== '' &&
                            <span className="error">*Origin from is required</span>
                        }
                    </div>                                         
                    <div className="input-container-pro">
                        <label>UPC (Optional)</label>
                        <input type={'text'} name="upc_code" onChange={(e) => onChangeInput(e)} value={addData['upc_code']} className="input-box form-control" placeholder="UPC" />
                    </div>  
                    <div className="input-container-pro" style={{flexDirection:'row',justifyContent:'space-between'}}>
                        <label>Presence</label>
                        <label className="switch">
                            <input type="checkbox"  name="presence" onChange={(e) => onChangeCheckPresense(e)} value={addData['presence']} />
                            <span className="slider round"></span>
                        </label>
                    </div> 
                    <div className="input-container-pro" style={{flexDirection:'row',justifyContent:'space-between'}}>
                        <label>All India Delivery</label>
                        <label className="switch">
                            <input type="checkbox"  name="global_delivery" onChange={(e) => onChangeCheckGlobal(e)} value={addData['global_delivery']}/>
                            <span className="slider round"></span>
                        </label>
                    </div>   
                </div>
                <div className="col-md-5 ">
                <div className="row">
                {
                    pack.map((item,index) => {
                        return (
                            <div className="col-md-12 file-container" key={index}>                        
                        <h3>Pack {index + 1}</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>MRP*</label>
                                    <input type={'number'} name="buyPrice" onChange={(e) => onPackChange(e, index)} value={pack[index]['buyPrice']} className="input-box form-control" placeholder="MRP" />
                                    <p>Inclusive GST</p>
                                </div> 
                            </div>
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>Offer Price*</label>
                                    <input type={'number'} name="sellPrice" onChange={(e) => onPackChange(e, index)} value={pack[index]['sellPrice']} className="input-box form-control" placeholder="Offer price" />
                                    <p>Inclusive GST</p>
                                </div> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>Discount Percentage</label>
                                    <input type={'number'} readOnly name="discPerc" onChange={(e) => onPackChange(e, index)} value={pack[index]['discPerc']} className="input-box form-control" placeholder="Discount Percentage" />
                                </div> 
                            </div>
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>Special Discount</label>
                                    <input type={'number'}  name="splDisc" onChange={(e) => onPackChange(e, index)} value={pack[index]['splDisc']} className="input-box form-control" placeholder="(Optional)" />
                                </div> 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="input-container-pro">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Pack Size*</label>
                                            <input type={'number'}  name="packSize" onChange={(e) => onPackChange(e, index)} value={pack[index]['packSize']}  className="input-box form-control" placeholder="Pack Size" />
                                        </div>
                                        <div className="col-md-6">
                                            
                                            <label>Select Unit*</label>
                                            <select className="input-box form-control" name="unit" onChange={(e) => onPackChange(e, index)} value={pack[index]['unit']}>
                                                <option value={''}>Select Unit</option>
                                                {
                                                    units.map((item,index) => <option key={index} value={item.measurement}>{item.measurement}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <p>Pack size represents how much is your item quantity associated with a unit of measurement For example if you want to sel 50 KG then your pack size will be 50</p>
                                </div>
                            </div>
                        </div>
                        { index > 0 &&
                            <button className="add-pack-btn" onClick={() => removePackSize(index)}> -Remove Pack Size</button>
                        }
                    </div>
                        )
                    })
                }
                </div>
                <button className="add-pack-btn" onClick={() => addPackSize()}>+Add Pack Size</button>                
                <div className="row">
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>GST (%)*</label>
                                    <input type={'number'}  name="gst"  onChange={(e) => onChangeInput(e)} value={addData['gst']}  className="input-box form-control" placeholder="GST" />
                                    {
                                    formErrors.gst !== '' &&
                                    <span className="error">*GST is required</span>
                                }
                                </div> 
                            </div>
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>Cess (%)</label>
                                    <input type={'number'}    className="input-box form-control" placeholder="(Optional)" />
                                </div> 
                            </div>
                            <div className="col-md-6">
                                <div className="input-container-pro">
                                    <label>Cess Amount (INR)</label>
                                    <input type={'number'}    className="input-box form-control" placeholder="(Optional)" />
                                </div> 
                            </div>
                        </div>
                    <div className={`input-container-pro ${errorClass(formErrors.min_order_qty || formErrors.measurement_unit)}`}>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Min. order quantity*</label>
                                <input type={'number'} name="min_order_qty" onChange={(e) => onChangeInput(e)} value={addData['min_order_qty']} className="input-box form-control" placeholder="Minimum Order quantity" />
                                {
                                    formErrors.min_order_qty !== '' &&
                                    <span className="error">*Min order quantity is required</span>
                                }
                            </div>
                            <div className="col-md-6">
                                
                                <label>Select Unit*</label>
                                <select className="input-box form-control" name="measurement_unit" onChange={(e) => onChangeInput(e)} value={addData['measurement_unit']} >
                                    <option value={''}>Select Unit</option>
                                    {
                                        units.map((item,index) => <option key={index} value={item.measurement}>{item.measurement}</option>)
                                    }
                                </select>
                                {
                                    
                                    formErrors.measurement_unit !== '' &&
                                    <span className="error">*Measurement unit is required</span>
                                }
                            </div>
                        </div>
                        <p><small>You need to mention the minimum quantity that you are selling and it represents the quantity that is associated with a unit of measurement for example: if your minimum quantity is 100 KG then mention 100</small></p>
                        
                        
                        
                        
                    </div>   
                    <button className="submit-button-pro" onClick={newProduct}>
                        Add Product
                    </button>
                </div>
            </div>
        </div>
        <Toaster />
        </div>
    )
}
export default AddProduct;